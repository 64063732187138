import { Environment } from '@remedeelabs/surveys-common';
import { DIST, VERSION } from '../version';

export const environment: Environment = {
  name: 'medical-staging',
  production: false,
  firebase: {
    apiKey: 'AIzaSyCVBN2CGg01K2yxaLVVGyOTSV6T0grzVmM',
    authDomain: 'remedee-medical-staging.firebaseapp.com',
    projectId: 'remedee-medical-staging',
    storageBucket: 'remedee-medical-staging.appspot.com',
    messagingSenderId: '64840593322',
    appId: '1:64840593322:web:7167c7237a6038ef3085d3',
    measurementId: 'G-MK62RS3Q0C',
  },
  sentry: {
    dsn: 'https://e0461d3d683671347ffdbec4d95fff67@o4504690115870720.ingest.us.sentry.io/4508614261276672',
    dist: DIST,
  },
  functionsBaseUrl:
    'https://europe-west1-remedee-medical-staging.cloudfunctions.net',
  version: VERSION,
};
